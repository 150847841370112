var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-2 position-relative d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"tile":""}},[_c('v-progress-linear',{attrs:{"striped":"","active":"","height":"5","value":"100","absolute":"","indeterminate":_vm.loading,"color":_vm.tileColor}}),_c('CardTitle',{staticClass:"pt-2",attrs:{"icon":_vm.titleIcon,"icon-color":_vm.tileColor}},[_c('v-row',{staticClass:"d-flex align-center",attrs:{"slot":"title","no-gutters":""},slot:"title"},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',[(_vm.loading)?_c('div',{staticStyle:{"display":"inline-block","height":"20px","overflow":"hidden","width":"20px"}},[_c('v-skeleton-loader',{attrs:{"type":"avatar","tile":""}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.isCloud)?_c('ConcurrencyInfo',{staticClass:"text-caption position-absolute",staticStyle:{"bottom":"2px"}}):_vm._e()],1)],1),_c('div',{staticClass:"d-flex align-end flex-column",attrs:{"slot":"action"},slot:"action"},[_c('v-btn',{staticClass:"button-transition w-100 d-flex justify-end",style:({
          'border-right': ("3px solid " + (_vm.tab == 'running'
              ? 'var(--v-primary-base)'
              : 'var(--v-appForeground-base)')),
          'box-sizing': 'content-box',
          'min-width': '100px'
        }),attrs:{"depressed":"","small":"","tile":"","icon":"","color":_vm.tab == 'running' ? 'primary' : ''},on:{"click":function($event){_vm.tab = _vm.tab == 'running' ? 'all' : 'running'}}},[_vm._v(" Running "),_c('v-icon',{attrs:{"small":""}},[_vm._v("access_time")])],1),_c('v-btn',{staticClass:"button-transition w-100 d-flex justify-end",style:({
          'border-right': ("3px solid " + (_vm.tab == 'submitted'
              ? 'var(--v-primary-base)'
              : 'var(--v-appForeground-base)')),
          'box-sizing': 'content-box',
          'min-width': '100px'
        }),attrs:{"depressed":"","small":"","tile":"","icon":"","color":_vm.tab == 'submitted' ? 'primary' : ''},on:{"click":function($event){_vm.tab = _vm.tab == 'submitted' ? 'all' : 'submitted'}}},[_vm._v(" Submitted "),_c('v-icon',{attrs:{"small":""}},[_vm._v("access_time")])],1)],1)],1),_c('v-card-text',{staticClass:"pa-0"},[(_vm.overlay)?_c('v-overlay',{attrs:{"absolute":"","z-index":"1"}},[_c('CancelAll',{attrs:{"flow-runs":_vm.cancellable},on:{"finish":_vm.refetch}})],1):(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}}):(!_vm.loading && _vm.runs.length === 0)?_c('v-list',{staticClass:"card-content"},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-icon',{staticClass:"mb-1",attrs:{"color":_vm.tileColor}},[_vm._v(" warning ")])],1),_c('v-list-item-content',{staticClass:"my-0 py-3"},[_c('div',{staticClass:"text-subtitle-1 font-weight-light",staticStyle:{"line-height":"1.25rem"}},[_vm._v(" You have no "+_vm._s(_vm.tab == 'running' ? 'running flows' : _vm.tab == 'submitted' ? 'submitted runs' : 'runs in progress')+" ")])])],1)],1):_c('v-list',{staticClass:"card-content"},[_c('v-slide-x-transition',{attrs:{"mode":"out-in","leave-absolute":"","group":""}},_vm._l((_vm.runs),function(run){return _c('v-lazy',{key:run.id,class:run.state == 'Cancelling' ? 'blue-grey lighten-5' : '',attrs:{"options":{
            threshold: 0.75
          },"min-height":"40px","transition":"fade"}},[_c('div',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-truncate d-inline-block",staticStyle:{"max-width":"50%"}},[_c('router-link',{class:run.state == 'Cancelling' ? 'text--disabled' : '',attrs:{"to":{
                        name: 'flow',
                        params: { id: run.flow.flow_group_id }
                      }}},[_vm._v(" "+_vm._s(run.flow.name)+" ")])],1),_c('div',{staticClass:"font-weight-bold d-inline-block"},[_c('v-icon',{staticStyle:{"font-size":"12px"}},[_vm._v(" chevron_right ")])],1),_c('div',{staticClass:"text-truncate d-inline-block text--disabled",staticStyle:{"max-width":"35%"}},[_c('router-link',{class:run.state == 'Cancelling' ? 'text--disabled' : '',attrs:{"to":{ name: 'flow-run', params: { id: run.id } }}},[_vm._v(" "+_vm._s(run.name)+" ")])],1)]),(run.state == 'Cancelling')?_c('v-list-item-subtitle',[_vm._v(" Cancelling... ")]):(run.start_time)?_c('v-list-item-subtitle',[_vm._v(" Running for "),_c('DurationSpan',{staticClass:"font-weight-bold",attrs:{"start-time":run.start_time}})],1):(run.state == 'Submitted')?_c('v-list-item-subtitle',[_vm._v(" Submitted for execution ")]):_vm._e()],1)],1),_c('v-divider',{staticClass:"my-1 mx-4 grey lighten-4"})],1)])}),1)],1),(_vm.runs && _vm.runs.length > 3)?_c('div',{staticClass:"pa-0 card-footer"}):_vm._e()],1),_c('v-spacer'),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),(_vm.overlay || (_vm.cancellable && _vm.cancellable.length > 0))?_c('v-btn',{staticStyle:{"z-index":"2"},attrs:{"small":"","depressed":"","plain":_vm.overlay,"color":_vm.overlay ? 'white' : 'primary',"width":"74","text":""},on:{"click":_vm.toggleOverlay}},[_vm._v(" "+_vm._s(_vm.overlay ? 'Close' : 'Stop all')+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }